import { entries } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { BigNumber as EthBigNumber } from 'ethers';

import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { EMarketName } from '../../ui-config/marketsConfig';
import { EControlPositionType, ILeveragedPosition } from '../../types/uniswapTokens';
import { useLeveragedErc20PositionsQuery } from './useLeveragedErc20PositionsQuery';
import { IAlmMarketData } from './useAlmMarketsQuery';
import { useAlmPoolsQuery } from './useAlmPoolQuery';

const INITIAL_DATA: ILeveragedPosition[] = [];

const getMarket = (almMarketData: IAlmMarketData[], leverageContractAddress: string) => {
  return almMarketData.find((item) => item.addresses.leverageAddress === leverageContractAddress);
}

export const useLeveragedErc20PositionsDataQuery = (addresses?: string[], invertPool?: boolean) => {
  const [
    chainId,
    isAlmMarketsReady,
    almMarketData,
  ] = useRootStore((store) => [
    store.currentChainId,
    store.isAlmMarketsReady,
    store.getCurrentChainAlmMarketData(),
  ]);

  const { data: leveragedErc20Positions, isLoading: isLoadingLeveragedPositions } = useLeveragedErc20PositionsQuery();
  const { uiAlmLeveragePositionService } = useSharedDependencies();
  const { data: almPools } = useAlmPoolsQuery({ chainId });

  const requestedAddresses = !addresses?.length
    ? leveragedErc20Positions.map((item) => item.position_address)
    : addresses;

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.LEVERAGED_ERC20_POSITIONS_DATA, chainId, requestedAddresses],
    queryFn: () => {
      const requestData = leveragedErc20Positions.reduce<
        Record<string, { almMarket: IAlmMarketData, addresses: string[] }>
      >((acc, position) => {
        if (requestedAddresses.includes(position.position_address)) {
          const almMarket = getMarket(almMarketData, position.leverage_contract_address);
          if (almMarket) {
            const dataProviderAddress = almMarket.addresses.leverageDataProviderAddress;
            if (acc[dataProviderAddress]) {
              acc[dataProviderAddress].addresses.push(position.position_address)
            } else {
              acc[dataProviderAddress] = {
                almMarket,
                addresses: [position.position_address],
              }
            }
          }
        }
        return acc;
      }, {});

      const requests = entries(requestData).map(([dataProviderAddress, { almMarket, addresses }]) => {
        const requestFunc = async () => {
          const marketResponse = await uiAlmLeveragePositionService.getPositionsData(chainId, dataProviderAddress, addresses);
          return { almMarket, addresses, marketResponse };
        };
        return requestFunc();
      });

      return Promise.all(requests);
    },
    enabled: isAlmMarketsReady && !isLoadingLeveragedPositions,
    refetchInterval: POLLING_INTERVAL,
    keepPreviousData: !Boolean(addresses?.length),
    select: (response) => response.reduce<ILeveragedPosition[]>((acc, { almMarket, addresses, marketResponse }) => {
      const almPool = almPools.find((pool) => {
        return pool.market.create_and_leverage_address === almMarket.addresses.createAndLeverageAddress;
      });
      marketResponse.forEach((item, index) => acc.push({
        ...item,
        uniswapV3Position: {
          controlType: EControlPositionType.AlmPosition,
          fee: 0,
          tickLower: 0,
          tickUpper: 0,
          almMarket: {
            marketId: almMarket?.marketId,
            marketName: almMarket?.marketName,
          },
          marketId: almPool?.cl_market.id,
          marketName: almPool?.cl_market.name as EMarketName,
          tokenId: 0,
          token0: invertPool ? item.token1 : item.token0,
          token1: invertPool ? item.token0 : item.token1,
          fee0: '0',
          fee1: '0',
          amount0: invertPool ? item.amount1.toString() : item.amount0.toString(),
          amount1: invertPool ? item.amount0.toString() : item.amount1.toString(),
          tickCurrent: 0,
          liquidity: EthBigNumber.from(0),
          sqrtPriceX96: EthBigNumber.from(0),
          vault: item.vault,
        },
        debtAsset: toChecksumAddress(item.debtAsset),
        positionAddress: addresses[index],
        debt: item.debt.toString(),
        revenueFee0: '0',
        revenueFee1: '0',
        revenueFeePercent: '0',
      }));
      return acc;
    }, [])
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
