import { useQuery } from '@tanstack/react-query';
import { FAST_POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useRootStore } from '../../store/root';
import { useProtocolDataContext } from '../useProtocolDataContext';
import { useWeb3Context } from '../../libs/hooks/useWeb3Context';

export interface ILeveragedErc20Position {
  user_address: string;
  position_address: string;
  leverage_contract_address: string;
  chain_id: number;
}

const INITIAL_DATA: ILeveragedErc20Position[] = [];

export const getLeveragedErc20Position = async (
  account: string,
  chainId: number,
  endpointURL: string,
) => {
  try {
    const url = `${endpointURL}/leverage/erc20_positions/${account}?chain_id=${chainId}`;
    const result = await fetch(url);
    const json: ILeveragedErc20Position[] = await result.json();
    return json;
  } catch {
    return [];
  }
};

export const useLeveragedErc20PositionsQuery = () => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const { currentAccount } = useWeb3Context();
  const [currentChainId] = useRootStore((store) => [store.currentChainId]);
  const apiUrl = currentNetworkConfig.yldrYpiUrl;

  const { data, ...rest } = useQuery({
    queryFn: () => getLeveragedErc20Position(currentAccount, currentChainId, apiUrl),
    queryKey: [QueryKeys.LEVERAGED_ERC20_POSITIONS, currentAccount, currentChainId],
    enabled: Boolean(currentAccount && currentChainId),
    refetchInterval: FAST_POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
