import { useQuery } from '@tanstack/react-query';
import { FAST_POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useRootStore } from '../../store/root';
import { useProtocolDataContext } from '../useProtocolDataContext';

export interface ILeveragedPosition {
  user_address: string;
  position_address: string;
  leverage_contract_address: string;
  chain_id: number;
}

const INITIAL_DATA: ILeveragedPosition[] = [];

export const getLeveragedPosition = async (
  account: string,
  chainId: number,
  endpointURL: string,
) => {
  try {
    const url = `${endpointURL}/leverage/positions/${account}?chain_id=${chainId}`;
    const result = await fetch(url);
    const json: ILeveragedPosition[] = await result.json();
    return json;
  } catch {
    return [];
  }
};

export const useLeveragedPositionsQuery = () => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const [account, chainId] = useRootStore((store) => [
    store.account,
    store.currentChainId,
  ]);
  const apiUrl = currentNetworkConfig.yldrYpiUrl;

  const { data, ...rest } = useQuery({
    queryFn: () => getLeveragedPosition(account, chainId, apiUrl),
    queryKey: [QueryKeys.LEVERAGED_POSITIONS, account, chainId],
    enabled: Boolean(account),
    refetchInterval: FAST_POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
